export const getCellClassFromCol = (col: any): string[] => {
  const cellClass: string[] = []
  let typeClass: string
  if (col.type === 'DATE') {
    typeClass = 'dateType'
  } else if (col.type === 'INTEGER') {
    typeClass = 'numberType'
  } else if (col.type === 'AMOUNT') {
    typeClass = 'currencyFormat'
  } else if (col.type === 'FILE') {
    typeClass = 'hyperlinks'
  } else {
    typeClass = 'stringType'
  }
  const colorClass = col.color && col.color !== null ? col.color : ''
  cellClass.push(typeClass, colorClass)
  return cellClass
}
